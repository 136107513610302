.projects-container {
  margin: 0 auto;
  padding: 50px 0;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 25rem;
  color: white;
}

.card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-details {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-details p {
  text-align: justify;
}

.project-link {
  text-decoration: none;
  color: rgb(108, 245, 255);
  padding: 1rem;
  border: 1px solid rgb(108, 245, 255);
  border-radius: 10px;
  margin: 2rem;
}

.project-link:hover {
  background-color: rgb(108, 245, 255);
  color: black;
}

.card-details h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

li {
  margin-bottom: 5px;
  font-size: 14px;
  color: white;
  border: 1px solid beige;
  padding: 1rem;
}

.ContactPage {
  padding: 1rem;
  height: 20vh;
}

@media (max-height: 2532px) {
  .ContactPage {
    margin-top: 2rem;
  }
}

.social-icon.ig {
  color: rgb(162, 135, 206);
}

.social-icon.li {
  color: rgb(136, 136, 255);
}

.social-icon.yt {
  color: rgb(255, 99, 99);
}

.social-icon:hover {
  color: white;
}