@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

body {
  overflow-x: hidden;
  background: rgb(245, 236, 196);
}

h1,
svg,
.skill > p,
.fa-youtube,
.fa-instagram,
.fa-linkedin  {
  color: rgb(43, 37, 37);
}

a > svg {
  color: rgb(0, 153, 255);
}

.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: rgb(192, 192, 192);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
