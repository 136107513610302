.Landing {
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: beige;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
} 

.Landing .blurb {
  margin: 50px;
  margin-top: 1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 10px;
}


.Landing div h1 {
  font-size: 48px;
  font-weight: bold;
}

.Landing div p {
  font-size: 24px;
}

img[alt="Profile"] {
  width: 400px;
  border-radius: 50%;
  transition: transform 0.5s ease-out;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

img[alt="Profile"]:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {

  .Landing .blurb {
    margin-top: 8rem;
    width: 80%;
  }
  
  img[alt="Profile"] {
    width: 275px;
    margin-top: -5rem;
  }

  .Landing {
    height: 110vh;
  }

}

@media (max-height: 450px) {

  .blurb {
    color: blue;
  }

}

.fa-file-lines {
  color: rgb(65, 65, 65);
  text-decoration: none;
}

.blurb > a:nth-child(3) > h5:nth-child(2) {
  color: rgb(65, 65, 65);
}

.blurb > p:nth-child(2) {
  font-weight: bold;
  color: rgb(65, 65, 65);
}