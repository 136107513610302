.logo {
  width: 80px;
  filter: invert(1);
}

nav {
  background: rgb(245, 236, 196);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

a, 
#root > div > nav > button {
  margin-left: 1rem;
}

a.nav-link {
  color: black;
  font-weight: bold;
}

.nav-right {
  display: flex;
  align-items: center;
}